.evenement .col:last-child {
margin-bottom: 0!important;
}

.py-sm-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem!important;
  }

  @media (max-width: 576px){
.legal {
display: block;
  }
}